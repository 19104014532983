export default {
    "textToast": {
        "sendQueryText": "Wir schätzen Ihr Feedback aufrichtig. Ihre Erfahrung ist uns wichtig, und wir bemühen uns, Ihre Erwartungen zu erfüllen.",
        "updateQueryText" : "Änderung gesendet",
        "registerResponse": "Feedback gesendet",
        "updateResponse": "Feedback aktualisiert"
    },
    "form": {
        "send": "Senden",
        "sending": "Wird gesendet",
        "modify" : "Ändern",
        "cancel" : "Stornieren",
        "title": "Anfragen",
        "hello": "Hallo",
        "whatsup": "Was gibt's?",
        "thanksAll" : "Danke für alles",
        "btn-verywrong-stay": "Sehr schlecht",
        "btn-wrong-stay": "Schlecht",
        "btn-normal-stay": "Normal",
        "btn-good-stay": "Gut",
        "btn-verygood-stay": "Sehr gut",
        "btn-verywrong-poststay": "Sehr schlecht",
        "btn-wrong-poststay": "Schlecht",
        "btn-normal-poststay": "Normal",
        "btn-good-poststay": "Gut",
        "btn-verygood-poststay": "Sehr gut",
        "poststay-bad-thanks-title": "Antwort gesendet",
        "poststay-bad-thanks-subtitle": "Vielen Dank für Ihre Zeit",
        "continueIn" : "Zu Hause"
    },
    "settings": {
        "questionpre-stay": "Können wir etwas für Ihre Ankunft im {lodging} tun?",
        "questionin-stay": "Wie würden Sie Ihre Zufriedenheit mit Ihrem Aufenthalt bisher bewerten?",
        "questionpost-stay": "Wie war Ihre Erfahrung mit uns?",
    },
    "inbox": {
        "title": "Nachrichten"
    }
}
