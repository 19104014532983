export default{
    "log-or-register": {
        "title": "Connectez-vous ou inscrivez-vous",
        "welcome-title": "Bienvenue !",
        "welcome-msg": "Profitez de notre WebApp et gérez vos séjours dans l'un de nos hôtels.",
        "input": "Entrez votre adresse e-mail",
        "text-error": "Le format de l'e-mail entré est incorrect",
        "continue-button": "Continuer",
        "continue-google": "Continuer avec Google",
        "continue-fb": "Continuer avec Facebook"
    },
    "log": {
        "title": "Connexion",
        "input-password": "Entrez un mot de passe",
        "password-text-error": "Le mot de passe entré est incorrect",
        "continue-button": "Continuer",
        "forgot-question": "Mot de passe oublié ?",
        "input-show-pass": "Afficher",
        "input-hide-pass": "Masquer"
    },
    "register": {
        "title": "Terminez votre inscription",
        "input-name": "Entrez votre nom",
        "input-email": "Entrez votre adresse e-mail",
        "input-email-error": "Le format de l'e-mail entré est incorrect",
        "input-password": "Entrez un mot de passe",
        "continue-button": "Accepter et continuer",
        "text-info-1": "vous enverra des communications commerciales et des promotions personnalisées par e-mail, SMS ou d'autres moyens électroniques concernant des produits et services",
        "text-info-2": "Je ne souhaite pas recevoir de messages promotionnels.",
        "policies-text": "En sélectionnant Accepter et continuer, j'accepte la",
        "policies-title": "Politique de Confidentialité.",
        "the-chain": "La chaîne",
        "password-info": "Le mot de passe doit contenir au moins 8 caractères."
    },
    "reset-pass":{
        "title": "Changer le mot de passe",
        "input-placeholder": "Entrez votre nouveau mot de passe",
        "input-input-error": "Le mot de passe entré est incorrect",
        "continue-button": "Changer le mot de passe",
        "greeting": "Bonjour",
        "title-reset": "Réinitialiser le mot de passe",
        "msg": "Vous avez demandé un lien pour réinitialiser le mot de passe de votre compte sur notre WebApp. Cliquez sur le bouton suivant pour commencer le processus.",
        "link": "Réinitialiser le mot de passe",
    },
    "hotel-list":{
        "title": "Sélectionnez l'hébergement pour créer votre séjour dans notre WebApp",
        "amount-text": "hébergements de la chaîne",
        "stars-text": "étoiles",
        "star-text": "étoile",
    }
    
    
}
