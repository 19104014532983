export default {
    "gocheckinButton":"Completar Check-in",
    "cardBanner": {
        "title": "Check-in disponible",
        "subtitle": "Optimiza tu tiempo, realiza tu check-in ahora!",
    },
    "card-guest": {
        "resend-staylink": "Reenviar enlace a la estancia",
        "invite-to-completecheckin": "Invitar a completar Check-in",
        "delete-checkin": "Eliminar Check-in de huésped",
        "delete-guest": "Eliminar huésped"
    },
    "delete-guest": {
        "title": "¿Eliminar huésped de la estancia?",
        "info": "Quitarás a este huésped de tu estancia. Podrás volver a enviarle una invitación luego.",
        "confirm": "Eliminar huésped",
        "cancel": "Cancelar",
    },
    "delete-checkin": {
        "title": "¿Eliminar Check-in del huésped?",
        "info": "Eliminarás la información de Check-in de este huésped. El huésped tendrá que volver a completar su Check-in.",
        "confirm": "Eliminar Check-in",
        "cancel": "Cancelar",
    },
    "header": {
        "mystay": "Estancia",
        "guests": "Huéspedes",
    }
}