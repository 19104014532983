export default {
    "textToast": {
        "sendQueryText": "We sincerely appreciate your feedback. Your experience matters to us, and we strive to meet your expectations.",
        "updateQueryText" : "Amendment submitted",
        "registerResponse": "Feedback submitted",
        "updateResponse": "Feedback updated"
    },
    "form": {
        "send": "Send",
        "sending": "Sending",
        "modify" : "Modify",
        "cancel" : "Cancel",
        "title": "Queries",
        "hello": "Hello",
        "whatsup": "What's up",
        "thanksAll" : "Thank you for everything",
        "btn-verywrong-stay": "Very bad",
        "btn-wrong-stay": "Bad",
        "btn-normal-stay": "Normal",
        "btn-good-stay": "Good",
        "btn-verygood-stay": "Very good",
        "btn-verywrong-poststay": "Very bad",
        "btn-wrong-poststay": "Bad",
        "btn-normal-poststay": "Normal",
        "btn-good-poststay": "Good",
        "btn-verygood-poststay": "Very good",
        "poststay-bad-thanks-title": "Reply sent", 
        "poststay-bad-thanks-subtitle": "Thank you very much for your time",
        "continueIn" : "Continue at"
    },
    "settings": {
        "questionpre-stay": "Is there anything we can do for your arrival at the {lodging}?",
        "questionin-stay": "How would you rate your level of satisfaction with your stay so far?",
        "questionpost-stay": "How was your experience with us?",
    },
    "inbox": {
        "title": "Messages"
    }
}
