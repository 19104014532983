export default  {
  "title": "Servizi",
  "modal-request-service": {
    "button": "Richiedi servizio",
    "title": "Stai per essere reindirizzato a un sito web esterno",
    "text": "Stai per essere reindirizzato al sito web del fornitore di servizi. Vuoi continuare?",
    "button-go": "Vai al sito"
  },
    "card-item": {
      "free": "Gratis"
    },
    "confort": {
      "title": "Confort",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Trasporto",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Attività",
      "list-page": {},
      "detail-page": {}
    }
  }