export default {
  "title": "Services",
  "modal-request-service": {
    "button": "Solicitar servicio",
    "title": "Estás por ser redirigido a un sitio web externo",
    "text": "Estás a punto de ser redirigido al sitio web del proveedor del servicio. ¿Deseas continuar?",
    "button-go": "Ir al sitio"
  },
  "card-item": {
    "free": "Gratis"
  },
  "confort": {
    "title": "Confort",
    "list-page": {},
    "detail-page": {}
  },
  "transport": {
    "title": "Transporte",
    "list-page": {},
    "detail-page": {}
  },
  "activity": {
    "title": "Actividades",
    "list-page": {},
    "detail-page": {}
  }
}