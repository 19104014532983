export default {
    "textToast": {
        "sendQueryText": "Apprezziamo sinceramente il tuo feedback. La tua esperienza è importante per noi e ci impegniamo a soddisfare le tue aspettative.",
        "updateQueryText" : "Modifica inviata",
        "registerResponse": "Feedback inviato",
        "updateResponse": "Feedback modificato"
    },
    "form": {
        "send": "Invia",
        "sending": "Invio in corso",
        "modify" : "Modificare",
        "cancel" : "Annulla",
        "title": "Domande",
        "hello": "Ciao",
        "whatsup": "Come va",
        "thanksAll" : "Grazie di tutto",
        "btn-verywrong-stay": "Molto male",
        "btn-wrong-stay": "Male",
        "btn-normal-stay": "Normale",
        "btn-good-stay": "Bene",
        "btn-verygood-stay": "Molto bene",
        "btn-verywrong-poststay": "Molto male",
        "btn-wrong-poststay": "Male",
        "btn-normal-poststay": "Normale",
        "btn-good-poststay": "Bene",
        "btn-verygood-poststay": "Molto bene",
        "poststay-bad-thanks-title": "Risposta inviata", 
        "poststay-bad-thanks-subtitle": "Grazie mille per il tuo tempo",
        "continueIn" : "Continua a"
    },
    "settings": {
        "questionpre-stay": "C'è qualcosa che possiamo fare per il tuo arrivo in {lodging}?",
        "questionin-stay": "Come valuteresti il tuo livello di soddisfazione finora?",
        "questionpost-stay": "Com'è stata la tua esperienza con noi?",
    },
    "inbox": {
        "title": "Messaggi"
    }
}
