export default{
    "log-or-register": {
        "title": "Log In or Register",
        "welcome-title": "Welcome!",
        "welcome-msg": "Enjoy our WebApp and manage your stays at any of our hotels.",
        "input": "Enter your email address",
        "text-error": "The email format entered is incorrect",
        "continue-button": "Continue",
        "continue-google": "Continue with Google",
        "continue-fb": "Continue with Facebook"
    },
    "log": {
        "title": "Log In",
        "input-password": "Enter a password",
        "password-text-error": "The password entered is incorrect",
        "continue-button": "Continue",
        "forgot-question": "Forgot your password?",
        "input-show-pass": "Show",
        "input-hide-pass": "Hide",
    },
    "register": {
        "title": "Finish Registering",
        "input-name": "Enter your name",
        "input-email": "Enter your email address",
        "input-email-error": "The email format entered is incorrect",
        "input-password": "Enter a password",
        "continue-button": "Accept and Continue",
        "text-info-1": "will send you commercial communications and personalized promotions by email, SMS, or other electronic means about products and services",
        "text-info-2": "I do not want to receive promotional messages.",
        "policies-text": "By selecting Accept and Continue, I agree to the",
        "policies-title": "Privacy Policy.",
        "the-chain": "The Chain",
        "password-info": "The password must contain at least 8 characters."

    },
    "reset-pass":{
        "title": "Change Password",
        "input-placeholder": "Enter your new password",
        "input-input-error": "The password entered is incorrect",
        "continue-button": "Change Password",
        "greeting": "Hello",
        "title-reset": "Reset Password",
        "msg": "You have requested a link to reset the password for your account on our WebApp. Click the following button to start the process.",
        "link": "Reset Password",
    },
    "hotel-list":{
        "title": "Select the accommodation to create your stay in our WebApp",
        "amount-text": "accommodations in the chain",
        "stars-text": "stars",
        "star-text": "star",
    }    
}
