export default{
    "log-or-register": {
        "title": "Anmelden oder registrieren",
        "welcome-title": "Willkommen!",
        "welcome-msg": "Genieße unsere WebApp und verwalte deine Aufenthalte in einem unserer Hotels.",
        "input": "Gib deine E-Mail-Adresse ein",
        "text-error": "Das eingegebene E-Mail-Format ist nicht korrekt",
        "continue-button": "Fortsetzen",
        "continue-google": "Mit Google fortfahren",
        "continue-fb": "Mit Facebook fortfahren"
    },
    "log": {
        "title": "Anmelden",
        "input-password": "Gib ein Passwort ein",
        "password-text-error": "Das eingegebene Passwort ist falsch",
        "continue-button": "Fortsetzen",
        "forgot-question": "Passwort vergessen?",
        "input-show-pass": "Anzeigen",
        "input-hide-pass": "Verbergen"
    },
    "register": {
        "title": "Registrierung abschließen",
        "input-name": "Gib deinen Namen ein",
        "input-email": "Gib deine E-Mail-Adresse ein",
        "input-email-error": "Das eingegebene E-Mail-Format ist nicht korrekt",
        "input-password": "Gib ein Passwort ein",
        "continue-button": "Akzeptieren und fortfahren",
        "text-info-1": "wird dir kommerzielle Mitteilungen und personalisierte Werbeaktionen per E-Mail, SMS oder anderen elektronischen Medien über Produkte und Dienstleistungen senden",
        "text-info-2": "Ich möchte keine Werbenachrichten erhalten.",
        "policies-text": "Indem ich Akzeptieren und fortfahren auswähle, stimme ich der",
        "policies-title": "Datenschutzrichtlinie zu.",
        "the-chain": "Die Kette",
        "password-info":"Das Passwort muss mindestens 8 Zeichen enthalten."
    },
    "reset-pass":{
        "title": "Passwort ändern",
        "input-placeholder": "Gib dein neues Passwort ein",
        "input-input-error": "Das eingegebene Passwort ist falsch",
        "continue-button": "Passwort ändern",
        "greeting": "Hallo",
        "title-reset": "Passwort zurücksetzen",
        "msg": "Du hast einen Link angefordert, um das Passwort deines Kontos in unserer WebApp zurückzusetzen. Klicke auf den folgenden Button, um den Prozess zu starten.",
        "link": "Passwort zurücksetzen",
    },
    "hotel-list":{
        "title": "Wählen Sie die Unterkunft aus, um Ihren Aufenthalt in unserer WebApp zu erstellen",
        "amount-text": "Unterkünfte der Kette",
        "stars-text": "Sterne",
        "star-text": "Stern",
    }
    
    
    
}
