export default {
  "title": "Dienstleistungen",
  "modal-request-service": {
    "button": "Dienst anfordern",
    "title": "Sie werden auf eine externe Website weitergeleitet",
    "text": "Sie werden auf die Website des Dienstanbieters weitergeleitet. Möchten Sie fortfahren?",
    "button-go": "Zur Website"
  },
    "card-item": {
      "free": "Kostenlos"
    },
    "confort": {
      "title": "Komfort",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Transport",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Aktivitäten",
      "list-page": {},
      "detail-page": {}
    }
  }